import React, { useEffect, useContext, useState, useCallback } from "react"
import HomeBackgroundVector from "../../Components/HomeBackgroundVector"
import { useNavigate, useLocation } from "react-router-dom"
import axios from "axios"
import NavContext from "../../NavContext"
import keyringController from "../../utils/init"
import { Link } from "react-router-dom"
import Nav from "../../Components/Nav"
import Footer from "../../Components/Footer"
import { socket } from "../../../src/utils/init"
import {
  checkDidAvailability,
  getDidRecommendations,
  openWindowAndDetectBlock,
} from "../../utils"
import mixpanel from "mixpanel-browser"
import Loader from "../../Components/Loader"
import { v4 as uuidv4 } from "uuid"
import UnblockPopupModal from "../../Components/Popup/UnblockPopupModal"
export default function SignUp() {
  const { dappName, hash, hashPass, userEmail, otpLogin, isDarkMode } =
    useContext(NavContext)
  console.log("otpLogin------------->", otpLogin)
  // const userEmail = "gzb4ytvup6@waterisgone.com"

  const [name, setName] = useState("")
  const [recommendations, setRecommendations] = useState([])
  const [available, setAvailable] = useState()
  const [loader, setLoader] = useState(false)
  const [showPopupBlockedModal, setShowPopupBlockedModal] = useState(false)
  const [pendingAction, setPendingAction] = useState<(() => void) | null>(null)
  const location = useLocation()
  const baseUrl = process.env.REACT_APP_BASE_URL || ""
  const [checkLoader, setCheckLoader] = useState(true)

  // useEffect(() => {
  //     setTimeout(() => {
  //         navigate('/welcome')
  //     }, 2000)
  // }, [])

  const getNameRecommendations = async (name) => {
    try {
      const { data } = await axios.get(
        `${baseUrl}/api/v2/get-name-recommendation?name=${name?.toLowerCase()}`
      )
      console.log("recommed", data?.data)
      setRecommendations(data?.data)
    } catch (err) {
      console.log(err)
    }
  }

  const checkIfAvailable = async (name) => {
    try {
      const { data } = await axios.post(`${baseUrl}/api/v1/did/check`, {
        did: name?.toLowerCase() + "@tria",
      })
      console.log("did", data?.response?.availabilityStatus)
      setAvailable(data?.response?.availabilityStatus)
    } catch (err) {
      console.log(err)
    }
  }

  const call2 = async () => {
    if (name.length > 3) {
      setLoader(true)
      const searchParams = new URLSearchParams(location.search)
      const origin = searchParams.get("origin")
      const type = otpLogin ? "otp" : "link"
      const fromClientId = localStorage.getItem("clientId") || ""
      const res = await keyringController.generateAccountByOTPOrLINK({
        triaName: name.toLowerCase() + "@tria",
        input: userEmail,
        hash: hash,
        password: hashPass,
        type: type,
        origin: document.referrer,
        //@ts-ignore
        accessToken: localStorage.getItem("accessToken"),
        //@ts-ignore
        IdToken: localStorage.getItem("idToken"),
        fromClientId: fromClientId,
      })
      console.log("res", res)
      if (res.success === true) {
        const publicVault = localStorage.getItem("tria.wallet.store")

        const encodedData = btoa(publicVault || "")
        const socketId = uuidv4()
        const proceedWithAction = () => {
          const isOpened = openWindowAndDetectBlock(
            `${process.env.REACT_APP_HOSTED_URL}/listenForSocket?socketId=${socketId}`,
            "_blank"
          )

          if (!isOpened) {
            setShowPopupBlockedModal(true)
            setPendingAction(() => proceedWithAction)
            return
          }

          // Rest of your logic here
          socket.emit("loginV2", { userId: socketId }, () => {
            console.log("Socket connection established")

            let emitCount = 0
            const maxEmits = 5

            const emitInterval = setInterval(() => {
              if (emitCount >= maxEmits) {
                clearInterval(emitInterval)
                socket.disconnect()
                console.log("Posting message to keyringController")
                keyringController.postMessage({
                  type: "Email Pwd Sign up",
                  success: true,
                  data: JSON.parse(
                    localStorage.getItem("tria.wallet.store") || "{}"
                  ),
                })
                return
              }

              socket.emit(
                "messageV2",
                { userId: socketId, message: encodedData },
                (error, result) => {
                  if (error) {
                    console.error("Error sending data through socket:", error)
                  } else {
                    console.log(
                      `Data sent successfully through socket (${
                        emitCount + 1
                      }/${maxEmits})`
                    )
                  }
                }
              )

              emitCount++
            }, 1000)
          })

          mixpanel.track({
            event: "Signed up - via link",
            properties: {
              "tria.wallet.store": localStorage.getItem("tria.wallet.store"),
            },
          })
        }
        proceedWithAction()
      }
      //window.open(`${origin}?verified=true`, "_self")
    }
  }

  const check = async () => {
    if (otpLogin) {
      setCheckLoader(false)
    }
    const refined_email = userEmail?.substring(0, userEmail.indexOf("@"))
    if (refined_email.length !== 0) {
      const more_refined_email = String(refined_email)?.toLowerCase()
      console.log("more refined email -->", more_refined_email)
      console.log("check", await checkDidAvailability(more_refined_email))
      if ((await checkDidAvailability(more_refined_email)) === true) {
        console.log("name after check", more_refined_email)
        setName(more_refined_email)
        checkIfAvailable(more_refined_email)
        getNameRecommendations(more_refined_email)
        setTimeout(() => {
          setCheckLoader(false)
        }, 1000)
      } else {
        const suggestedName = await getDidRecommendations(more_refined_email)
        setName(suggestedName)
        checkIfAvailable(suggestedName)
        getNameRecommendations(suggestedName)
        setTimeout(() => {
          setCheckLoader(false)
        }, 1000)
      }
    }
  }

  useEffect(() => {
    check()
  }, [])

  const checkSpecialChar = (e) => {
    if (!/[0-9a-zA-Z]/.test(e.key)) {
      e.preventDefault()
    }
  }

  useEffect(() => {
    //Mixpanel track page view - Link Sign Up - Select Tria Name
    mixpanel.track_pageview({ page: "Link Sign Up - Select Tria Name" })

    socket.emit("loginV2", {
      userId: localStorage.getItem("socketId"),
    })
    const interval = setInterval(() => {
      // console.log("store", localStorage.getItem('tria.wallet.store'))
      if (localStorage.getItem("tria.wallet.store") !== null) {
        socket.emit("messageV2", {
          userId: localStorage.getItem("socketId"),
          //@ts-ignore
          message: JSON.parse(localStorage.getItem("tria.wallet.store")),
        })
        clearInterval(interval)
      }
    }, 1000)
  }, [])
  const handlePopupBlockedOk = useCallback(() => {
    setShowPopupBlockedModal(false)
    if (pendingAction) {
      pendingAction()
      setPendingAction(null)
    }
  }, [pendingAction])
  return (
    <>
      <div className=''>
        {showPopupBlockedModal && (
          <UnblockPopupModal handlePopupBlockedOk={handlePopupBlockedOk} />
        )}
        {checkLoader === false ? (
          <div className="w-full h-screen p-3 bg-white dark:bg-fontLightColor rounded-2xl flex-col justify-between items-center inline-flex font-['Montserrat']">
            <div className='absolute top-0 left-0 '>
              {" "}
              {isDarkMode ? (
                <img className='w-3/4' src='/icons/back.svg' />
              ) : (
                <div className='-ml-8 w-3/4 '>
                  <HomeBackgroundVector />
                </div>
              )}
            </div>
            <Nav />
            <div className='self-stretch flex-col justify-center  items-center gap-2 flex'>
              <div className='self-stretch  flex-col justify-start  items-center gap-3 flex rounded-md'>
                <div className='w-full px-3 py-4 rounded-2xl mt-auto border border-violet-400 border-opacity-30 flex-col justify-center items-center gap-0 inline-flex'>
                  <div className='self-stretch py-0 flex-col justify-center items-start gap-0 flex mb-2'>
                    <div className='self-stretch justify-start items-center gap-0 inline-flex '>
                      <div className="mix-blend-difference text-center text-white text-opacity-80 text-xs font-medium font-['Montserrat']  leading-snug">
                        Create your username
                      </div>
                    </div>
                  </div>
                  <div className='w-full px-0 justify-start items-center inline-flex ml-2 -mt-1'>
                    <div className='grow shrink basis-0 mix-blend-difference'>
                      <div>
                        <span
                          className='text-[10px] md:text-xs'
                          style={{
                            color: "white",
                            opacity: 0.5,
                            fontWeight: "normal",
                          }}
                        >
                          This will be your in-game name.
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='self-stretch h-12 flex-col justify-center items-center flex  '>
                    <div className='justify-between gap-2 items-center flex w-full'>
                      <div className=' h-7 text-xs px-3 py-3 bg-zinc-500 bg-opacity-10 w-full rounded-[20px] justify-between items-center flex '>
                        <input
                          style={{ textTransform: "lowercase" }}
                          onKeyDown={(e) => {
                            checkSpecialChar(e)
                          }}
                          className=" bg-transparent px-2 py-2 font-['Montserrat'] focus:outline-none dark:text-white text-black"
                          placeholder='Your name'
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value)
                            getNameRecommendations(e.target.value)
                            checkIfAvailable(e.target.value)
                          }}
                        />
                      </div>
                      <div
                        onClick={() => {
                          if (loader === false && available === true) {
                            call2()
                          }
                        }}
                        className=' cursor-pointer h-7 px-5 py-3 mix-blend-difference bg-white bg-opacity-90 rounded-[20px] justify-center items-center flex'
                      >
                        <div className='justify-center items-center flex'>
                          <button>
                            {" "}
                            <div className='text-center text-stone-950 text-xs font-semibold font-Montserrat leading-tight'>
                              {loader === false ? (
                                <span>Next</span>
                              ) : (
                                <div className='flex space-x-1 justify-center items-center '>
                                  <div
                                    style={{ backgroundColor: "gray" }}
                                    className='h-[6px] w-[6px] bg-white rounded-full animate-bounce [animation-delay:-0.3s]'
                                  ></div>
                                  <div
                                    style={{ backgroundColor: "gray" }}
                                    className='h-[6px] w-[6px] bg-white rounded-full animate-bounce [animation-delay:-0.15s]'
                                  ></div>
                                  <div
                                    style={{ backgroundColor: "gray" }}
                                    className='h-[6px] w-[6px] bg-white rounded-full animate-bounce'
                                  ></div>
                                </div>
                              )}
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {name.length !== 0 ? (
                    <div className='self-stretch justify-start items-center gap-2 inline-flex'>
                      {available === true ? (
                        <div className="text-center text-green-600 text-xs font-semibold font-['Montserrat'] leading-[16.80px] flex gap-1 items-center">
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='16'
                            height='16'
                            viewBox='0 0 24 24'
                            fill='none'
                          >
                            <path
                              d='M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z'
                              fill='#14AE5C'
                            />
                          </svg>
                          username available
                        </div>
                      ) : (
                        <div className="text-center text-red-500 text-xs font-semibold font-['Montserrat'] leading-[16.80px] flex gap-1 items-center">
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='16'
                            height='16'
                            viewBox='0 0 24 24'
                            fill='none'
                          >
                            <path
                              d='M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM15.36 14.3C15.65 14.59 15.65 15.07 15.36 15.36C15.21 15.51 15.02 15.58 14.83 15.58C14.64 15.58 14.45 15.51 14.3 15.36L12 13.06L9.7 15.36C9.55 15.51 9.36 15.58 9.17 15.58C8.98 15.58 8.79 15.51 8.64 15.36C8.35 15.07 8.35 14.59 8.64 14.3L10.94 12L8.64 9.7C8.35 9.41 8.35 8.93 8.64 8.64C8.93 8.35 9.41 8.35 9.7 8.64L12 10.94L14.3 8.64C14.59 8.35 15.07 8.35 15.36 8.64C15.65 8.93 15.65 9.41 15.36 9.7L13.06 12L15.36 14.3Z'
                              fill='#DA4343'
                            />
                          </svg>
                          username not available
                        </div>
                      )}
                    </div>
                  ) : null}
                  {name.length > 0 ? (
                    <div className='w-full'>
                      <div className='self-stretch justify-start items-center gap-2 inline-flex mt-4'>
                        <div className='mix-blend-difference text-center text-gray-400 text-[11px] font-medium font-Montserrat leading-snug'>
                          Recommended :{" "}
                        </div>
                      </div>
                      <div className='self-stretch justify-start items-center gap-2 inline-flex'>
                        <div className='flex flex-wrap gap-1 items-center w-full px-0 h-16 overflow-y-auto'>
                          {recommendations?.slice(0, 3)?.map((item, index) => {
                            return (
                              <div
                                onClick={() => {
                                  setName(item)
                                  checkIfAvailable(item)
                                }}
                                key={index}
                                className='self-stretch cursor-pointer justify-start items-center gap-2 inline-flex mt-1'
                              >
                                <div className='bg-zinc-500 bg-opacity-5 dark:text-white text-black text-[10px] dark:text-opacity-80 text-opacity-80 rounded-full px-5 py-2 font-Montserrat'>
                                  {item}
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <Footer />
            </div>
          </div>
        ) : (
          <div className='fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
            <Loader />
          </div>
        )}
      </div>
    </>
  )
}
