import React from "react"

const UnblockPopupModal = ({
  handlePopupBlockedOk,
}: {
  handlePopupBlockedOk: () => void
}) => {
  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          backdropFilter: "blur(5px)",
          zIndex: 100,
          opacity: 1,
          transition: "opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1)",
        }}
      />
      <div className='fixed top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] transform z-[1000] bg-[#101010] rounded-[20px] border-gray-900 border-solid border-[1px] px-[16px] py-[24px] w-[300px] h-auto flex flex-col justify-center items-center gap-[20px]'>
        {/* <p className='w-full text-[#FFF] text-[18px] font-bold leading-[120%]'>
        Please Enable Popups To Proceed!
      </p> */}

        <p className='w-full text-[#FFF] text-[16px] font-[500] leading-[120%]'>
          To continue, please enable popups!
        </p>
        <ol>
          <li className='w-full text-[#FFF] text-[16px] font-[500] leading-[120%]'>
            Look for the popup blocker icon (🚫) in your browser's address bar
            and allow.
          </li>
        </ol>
        <p className='w-full text-[#FFF] text-[16px] font-[500] leading-[120%]'>
          Then Click Ok to proceed!
        </p>

        <button
          onClick={handlePopupBlockedOk}
          className='bg-[#FFF] w-[100px] h-[40px] rounded-[20px] text-[#101010] flex items-center justify-center px-[20px] py-[12px]'
        >
          OK
        </button>
      </div>
    </>
  )
}

export default UnblockPopupModal
